body {
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	color: #666;
	font-size: 14px;
	line-height: 20px;
	width: 100%;
}
h1, h2, h3, h4, h5, h6 {
	color: #444;
}
/* default font size */
.fa {
	font-size: 14px;
}
/* Override the bootstrap defaults */
h1 {
	font-size: 33px;
}
h2 {
	font-size: 27px;
}
h3 {
	font-size: 21px;
}
h4 {
	font-size: 15px;
}
h5 {
	font-size: 12px;
}
h6 {
	font-size: 10.2px;
}
a {
	color: #6ca600;
}
a:hover {
	text-decoration: none;
	color: #639900;
	outline: none
}
a:focus,
a:active {
	color: #639900;
	outline: none
}
legend {
	font-size: 18px;
	padding: 7px 0px
}
label {
	font-size: 12px;
	font-weight: normal;
}
select.form-control, textarea.form-control, input[type="text"].form-control, input[type="password"].form-control, input[type="datetime"].form-control, input[type="datetime-local"].form-control, input[type="date"].form-control, input[type="month"].form-control, input[type="time"].form-control, input[type="week"].form-control, input[type="number"].form-control, input[type="email"].form-control, input[type="url"].form-control, input[type="search"].form-control, input[type="tel"].form-control, input[type="color"].form-control {
	font-size: 12px;
    border-radius: 0;
    -webkit-box-shadow: inset 0 0 0;
    box-shadow: inset 0 0 0;
}
.input-group input, .input-group select, .input-group .dropdown-menu, .input-group .popover {
	font-size: 12px;
}
.input-group .input-group-addon {
	font-size: 12px;
	height: 30px;
    border-radius: 0
}
#input-sort,
#input-limit {
    border-radius: 0;

}

#content .well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 0;
    -webkit-box-shadow: inset 0 0 0;
    box-shadow: inset 0 0 0;
}
.container {
    overflow: hidden
}

/* Fix some bootstrap issues */
span.hidden-xs, span.hidden-sm, span.hidden-md, span.hidden-lg {
	display: inline;
}

.list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.nav-tabs {
	margin-bottom: 15px;
}
div.required .control-label:before {
	content: '* ';
	color: #F00;
	font-weight: bold;
}
/* Gradent to all drop down menus */
.dropdown-menu li > a:hover {
	text-decoration: none;
	color: #fff !important;
	background-color: #0071eb;
	background-image: none;
	background-repeat: repeat-x;
}
/* top */
#top {
	background-color: #4a4a4a;
	margin: 0 0 20px 0;
	min-height: 30px;
	color: #fff
}
#top .container {
	padding: 0 20px;
    overflow: visible
}
.top-adres {
	margin-top: 5px;
	font-size: 12px;
	text-transform: uppercase;
}
#top #form-currency .currency-select,
#top #form-language .language-select {
	text-align: left;
}
#top #form-currency .currency-select:hover,
#top #form-language .language-select:hover {
	text-shadow: none;
	color: #ffffff;
	background-color: #229ac8;
	background-image: linear-gradient(to bottom, #23a1d1, #1f90bb);
	background-repeat: repeat-x;
}
.language-block .dropdown-menu {
    border-radius: 0;
    left: -31px;
    margin-top: 0
}
#top .btn-link, #top-links li, #top-links a {
	text-decoration: none;
    text-align: left;
}
#top .btn-link:hover, #top-links a:hover {
	color: #444;
}
#top-links .dropdown-menu a {
	text-shadow: none;
}
#top-links .dropdown-menu a:hover {
	color: #FFF;
}
#top .btn-link strong {
	font-size: 14px;
        line-height: 14px;
}
#top-links {
	padding-top: 6px;
}
#top-links a + a {
	margin-left: 15px;
}
.dop-menu {
    list-style: none;
    margin: 0;
    padding: 0
}
.dop-menu__item {
	display: inline-block;
	float: left;
	margin: 0 2em;
	padding-top: 15px;
}
.dop-menu__link {
    color: #fff;
    text-transform: uppercase;
		font-size: 16px;
    font-weight: bold;
}
.dop-menu__link:hover {
    color: #4a4a4a;
    text-decoration: none
}

/* Language */
.top-user-block {
    text-align: right;
    padding-top: 5px
}
.top-user-block,
.top-user-block a,
.language-block,
.language-block span,
.language-block i,
.language-block a {
    color: #fff
}
.top-user-block a:hover {
    text-decoration: underline
}
.language-block {
    background: #0071eb;
    text-align: center
}
.language-block span {
    text-transform: uppercase;
    margin: 0 5px;

}
.language-block img {
    margin-right: 8px;
}
.language-block button,
.language-block button:hover {
     color: #fff !important;
}

/* logo */
#logo {
	margin: -40px 0 0 0;
	text-align: center;
}
#logo a {
	display: inline-block;
	margin: 0 auto;
}
/* search */
#search {
	margin-bottom: 10px;
  margin-top: 35px;
}
#search .input-lg {
	    height: 38px;
    line-height: 20px;
    padding: 0 10px;
    border: 1px solid #ccc;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
    box-shadow: none;
}
#search .btn-lg {
	font-size: 15px;
    line-height: 18px;
    padding: 8px 20px 12px;
    text-shadow: none;
    background: #7dc200;
    color: #fff;
    border: 0;
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
}
#search .btn-lg:hover {
    background: #74b300;
}

.header-right {
	display: flex;
	margin-top: 18px
}

/* Top Phone */
.top-phone {
        display: inline-block;
    margin-top: 9px;
        margin-left: -50px;
    position: relative;
    padding: 4px 0 0 30px;
    max-width: 185px;
}
.top-phone__link {
    font-size: 16px;
    color: #4a4a4a;
    line-height: 21px;
    text-decoration: none;
    white-space: nowrap
}
.top-phone__link:hover {
    color: #4a4a4a;
    text-decoration: none
}
.top-phone:before {
	content: '\f095';
position: absolute;
height: 24px;
width: 18px;
top: 0;
left: 0;
background: #fff;
color: #7dc200;
font-family: FontAwesome;
font-style: normal;
font-weight: normal;
text-decoration: inherit;
font-size: 24px;
line-height: 36px;
text-align: center;
}


/* cart */
#cart {
	margin-bottom: 10px;
    float: right;
    width: auto;
    margin-top: 9px
}
#cart > .btn {
	font-size: 12px;
	line-height: 18px;
	color: #FFF;
}
#cart.open > .btn {
	background-image: none;
	color: #666;
    font-size: 14px
	box-shadow: none;
	text-shadow: none;
}
#cart.open > .btn:hover {
	color: #444;
}
#cart .btn-top-cart {
    background: transparent;
    border: 0;
    border-radius: 25px;
    font-size: 14px;
	line-height: 21px;
	color: #4a4a4a;
    max-width: 260px;
    padding: 14px 30px;
    position: relative;
		border: 1px solid #7dc200;
}
.btn-top-cart:before {
    content: '\f07a';
    position: absolute;
    height: 49px;
    width: 49px;
    top: 0;
    left: 0;
    color: #7dc200;
    font-family: FontAwesome;
font-style: normal;
font-weight: normal;
text-decoration: inherit;
    font-size: 16px;
    line-height: 48px
}
span#cart-total {
    margin-left: 30px;
    font-weight: bold;
}
#cart .dropdown-menu {
	background: #eee;
	z-index: 1001;
}
#cart .dropdown-menu {
	min-width: 100%;
}
@media (max-width: 478px) {
	#cart .dropdown-menu {
		width: 100%;
	}
}
#cart .dropdown-menu table {
	margin-bottom: 10px;
}
#cart .dropdown-menu li > div {
	min-width: 427px;
	padding: 0 10px;
}
@media (max-width: 478px) {
	#cart .dropdown-menu li > div {
		min-width: 100%;
	}
}
#cart .dropdown-menu li p {
	margin: 20px 0;
}
/* menu */
#menu.navbar {
	margin-bottom: 0
}
.main-navigation {
    background: #7dc200
}
.main-navigation .container,
header .container {
    overflow: visible
}
.top-vmenu {
    position: relative;
		background: #74b300
}
#menu .navbar-collapse {
	padding-left: 0;
	padding-right: 0
}
.navbar-nav.vmenu_cat_items,
.navbar-nav>li.vmenu_cat_item {
	width: 100%
}
.navbar-nav>li>a.vmenu_cat_item-link {
	font-size: 16px;
	font-weight: bold;
	text-transform: uppercase;
	padding: 15px 20px;
	color: #fff;
	text-align: left;
	position: relative;
	width: 100%
}
.navbar-nav>li>a.vmenu_cat_item-link:before {
	content: "\f0c9";
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	text-decoration: inherit;
	display: block;
	width: 14px;
	height: 14px;
	color: #fff;
	position: absolute;
	right: 20px;
	top: 14px;
	z-index: 5
}
.navbar-nav>li>a.vmenu_cat_item-link:hover,
.navbar-nav>li>a.vmenu_cat_item-link:focus {
	background: #74b300
}




.top-vmenu__title {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 15px 20px 15px 50px;
    color: #fff;
    text-align: left;
    position: relative;
}
.top-vmenu__title:before {
    content: "\f0c9";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    display: block;
    width: 14px;
    height: 14px;
    color: #fff;
    position: absolute;
    left: 20px;
    top: 14px;
    z-index: 5
}
.top-vmenu__title-link,
.top-vmenu__title-link:hover,
.top-vmenu__title-link:focus {
    color: #fff;
    text-decoration: none;
    outline: none;
    display: block;
    position: relative;
    z-index: 10
}
.top-vmenu__block {
    position: absolute;
    top: 50px;
    height: auto;
    width: 100%;
    background: #f5f5f5;
    color: #4d4d4d;
    z-index: 1000;
    overflow: hidden;
    padding-bottom: 35px;
    transition-duration: 1s;
    transition-timing-function: linear;
}
.top-vmenu__block--show {
    display: block !important
}
.top-vmenu__block.top-vmenu__block--height {
     max-height: 496px;
    transition-duration: 1s;
    transition-timing-function: linear;
}
.top-vmenu__items {
    margin: 0;
    padding: 0;
    list-style: none
}
.top-vmenu__item {
    margin: 0;
    list-style: none;
    padding: 0 20px;
}
.top-vmenu__link {
    font-size: 14px;
    color: #4a4a4a;
    border-top: 1px solid #e6e6e6;
    display: block;
    padding: 10px 0;
}
.top-vmenu__open {
    display: block;
    position: absolute;
    bottom: 0;
    background: #f5f5f5;
    width: 100%;
    padding: 12px 20px;
    font-size: 14px;
}
.top-vmenu__close {
    display: none;
    position: absolute;
    bottom: 0;
    background: #f5f5f5;
    width: 100%;
    padding: 12px 20px;
    font-size: 14px;
}
.top-vmenu__open:hover,
.top-vmenu__close:hover {
    cursor: pointer
}
.top-vmenu__open span,
.top-vmenu__close span {
    border-bottom: 1px dashed #337ab7
}
.top-hmenu .navbar {
    margin-bottom: 0
}
.top-hmenu .navbar .navbar-collapse {
    padding: 0
}
.price-downlod {
    background: #f5f5f5;
    text-align: center
}
.price-downlod__link {
    display: inline-block;
    color: #0063cc;
    border-bottom: 1px dashed #0063cc;
    padding-bottom: 1px;
    margin: 14px 0;
    font-size: 14px;
    text-transform: uppercase;
}
.top-hmenu #menu .nav > li > a {
	color: #4a4a4a;
	padding: 14px 8px;
	min-height: 15px;
	background-color: transparent;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold
}
.top-hmenu #menu .nav > li > a:hover, #menu .nav > li.open > a {
    color: #808080
}
#menu .dropdown-menu {
	padding: 0;
	background: #f5f5f5;
	border-radius: 0;
	width: 100%
}
#menu .dropdown-inner {
	display: table;
	width: 100%
}
#menu .dropdown-inner ul {
	display: table-cell;
}
#menu .dropdown-inner a {
	font-size: 14px;
    color: #4a4a4a;
    border-top: 1px solid #e6e6e6;
    display: block;
    padding: 10px 0;
}
#menu .dropdown-menu li > a:hover {
    text-decoration: none;
    color: #74b300 !important;
    background-color: transparent;
    background-image: none;
    background-repeat: no-repeat;
}
#menu .dropdown-inner li a:hover {
	color: #FFFFFF;
}
#menu .see-all {
	display: block;
	margin-top: 0.5em;
	border-top: 1px solid #DDD;
	padding: 3px 20px;
	-webkit-border-radius: 0 0 4px 4px;
	-moz-border-radius: 0 0 4px 4px;
	border-radius: 0 0 3px 3px;
	font-size: 12px;
}
#menu .see-all:hover, #menu .see-all:focus {
	text-decoration: none;
	color: #ffffff;
	background-color: #229ac8;
	background-image: linear-gradient(to bottom, #23a1d1, #1f90bb);
	background-repeat: repeat-x;
}
#menu #category {
	float: left;
	padding-left: 15px;
	font-size: 16px;
	font-weight: 700;
	line-height: 40px;
	color: #fff;
	text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
}
#menu .btn-navbar {
	font-size: 15px;
	font-stretch: expanded;
	color: #FFF;
	padding: 2px 18px;
	float: right;
	background-color: #229ac8;
	background-image: linear-gradient(to bottom, #23a1d1, #1f90bb);
	background-repeat: repeat-x;
	border-color: #1f90bb #1f90bb #145e7a;
}
#menu .btn-navbar:hover, #menu .btn-navbar:focus, #menu .btn-navbar:active, #menu .btn-navbar.disabled, #menu .btn-navbar[disabled] {
	color: #ffffff;
	background-color: #229ac8;
}
@media (max-width: 1199px) {
 .home-advantage__item {
        text-align: center !important;
     width: 32% !important;
    }
    .home-advantage__txt {
        margin-left: 0 !important;
    }
    .home-advantage__icon {
        float: none !important;
        display: block !important;
    }
}


@media (min-width: 768px) {
	#menu .dropdown:hover .dropdown-menu {
		display: block;
	}
}
@media (max-width: 767px) {
    footer h5 {
        margin-bottom: 10px
    }
    footer .list-unstyled {
        margin-bottom: 25px
    }
	#menu {
		border-radius: 4px;
	}
	#menu div.dropdown-inner > ul.list-unstyled {
		display: block;
	}
	#menu div.dropdown-menu {
		margin-left: 0 !important;
		padding-bottom: 10px;
		background-color: rgba(0, 0, 0, 0.1);
	}
	#menu .dropdown-inner {
		display: block;
	}
	#menu .dropdown-inner a {
		width: 100%;
		color: #fff;
	}
	#menu .dropdown-menu a:hover,
	#menu .dropdown-menu ul li a:hover {
		background: rgba(0, 0, 0, 0.1);
	}
	#menu .see-all {
		margin-top: 0;
		border: none;
		border-radius: 0;
		color: #fff;
	}
    .main-navigation .top-vmenu {
        margin: 0 -15px
    }

    #logo img {
        margin: 0 auto
    }
    .top-phone {
        max-width: 300px;
    margin: 0 auto;
    text-align: center;
    font-weight: bold;
    display: block;
    padding: 0;
    }
    .top-phone:before {
        display: none
    }
    .dop-menu:after {
        content: '';
        display: block;
        clear: both
    }

    .top-user-block {
        text-align: left
    }
    .language-block .btn-link {
        color: #fff
    }
    .language-block img {
        margin-right: 8px
    }
    #cart {
    margin: 9px auto 15px;
    float: none !important;
    display: block;
    text-align: center;
    }
    #cart:after {
        content: '';
        clear: both;
        display: block
    }
    #cart .btn-top-cart {
        width: 260px;
        left: 50%;
        margin-left: -130px
    }
    .home-news .home-news__col {
        width: 100%;
        float: none;
        display: block
    }
    .home-news .home-news__block {
        margin-right: 0
    }
    .home-news .home-news__col:nth-child(odd) .home-news__block {
    margin-left: 0;
    margin-right: 0;
}
    .home-news .home-news__description-block {
    margin-left: 120px;
}
     .home-news .home-news__description-block h3 {
    margin: 3px 0;
    font-size: 16px;
    line-height: 18px;
}
    .home-advantage__list .home-advantage__item {
    display: block;
    width: 100% !important;
}
    .home-advantage__list .home-advantage__item:after {
        content: '';
        clear: both;
        display: block
    }

}



/* Slider */
.home-slider {
    margin-top: 30px
}
/*.home-slider .swiper-viewport {
    height: 335px;
    margin-bottom: 30px
}*/
.home-slider .swiper-pagination {
    bottom: 30px
}

.home-advantage {
    background: #f5f5f5;
    padding: 20px;
}
.home-advantage__list {
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0
}
.home-advantage__item {
    display: inline-block;
    width: 33%
}
.home-advantage__icon {
    display: inline-block;
    float: left
}
.home-advantage__txt {
    display: inline-block;
    margin-left: 10px;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
    margin-top: 8px;
}

.home-line {
    background: #007bff;
    height: 8px;
    margin: 30px 0;
    width: 100%
}

.home-block__title {
    margin: 0 0 45px;
    color: #4c4c4c;
    font-weight: bold;
    text-transform: uppercase;
    padding-bottom: 15px;
		text-align: center;
}


/* Bestseller */
.home-bestseller {
    background: #f5f5f5;
    padding: 90px 0 65px;
    border-top: 1px solid #ebebeb
}
.home-bestseller .swiper-container {
    padding-bottom: 40px
}
.home-bestseller .swiper-container-horizontal .swiper-pagination-bullets {
    bottom: 10px
}




/* Latest */
.home-latest {
    padding: 50px 0 65px;
}
.home-latest .swiper-container {
    padding-bottom: 40px
}
.home-latest .swiper-container-horizontal .swiper-pagination-bullets {
    bottom: 10px
}



/* News */
.home-news {
    padding: 90px 0 65px
}
.home-news__col {
     width: 50%;
    display: inline-block;
    float: left;
}
.home-news__block {
    border: 1px solid #e6e6e6;
    margin-right: 15px;
    height: 100px;
    overflow: hidden;
    margin-bottom: 15px;
}
.home-news__col:nth-child(odd) .home-news__block {
    margin-left: 15px;
    margin-right: 0
}

.home-news__img-block {
    width: 100px;
    display: inline-block;
    float: left
}
.home-news__img-block img {
    width: 100%
}
.home-news__description-block {
    margin-left: 135px
}
.home-news__date {
    margin-top: 10px;
    font-size: 12px;
    color: #999
}
.home-news__description-block h3 {
    margin: 3px 0
}
.home-news__description-block h3 a {
    font-size: 14px;
    line-height: 18px;
    font-weight: bold
}

.news-list__box {
    display: block;
    border: 1px solid #e6e6e6;
    margin-bottom: 15px;
}
.news-list__box:after {
    content: '';
    clear: both;
    display: block
}
.news-list__img {
    float: left;
    margin-right: 20px
}
.news-list__description {
    padding: 10px 15px
}
.news-list__date {
    font-size: 12px;
    color: #999;
}
.news-list__title a {
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
        margin: 3px 0;
}
.news-list__link a {
    font-size: 12px;
    line-height: 16px;
    font-weight: normal;
        margin: 3px 0;
}




/* Subscribe */
.footer-subscribe #form-newsletter input[type="email"].form-control {
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border: 0px;
    background: #fff;

}
.footer-subscribe .subscribe-btn {
    background: #3c98fa;
    border-bottom-right-radius: 30px;
    border-top-right-radius: 30px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 40px;
    font-size: 14px;
    color: #fff;
    font-weight: bold;
       padding-left: 25px;
    padding-right: 25px;
    margin-left: -4px;
    border: 0;
    margin-top: 1px;
}
.footer-subscribe .subscribe-btn:hover {
    background: #007bff
}

/* Social */
.social-items {
	margin: 9px 0;
	padding: 0;
	list-style: none;
	text-align: right;
}
.social-item {
    display: inline-block;
    margin-right: 10px;
}
.social-icon {
    text-align: center;
    font-size: 24px;
    color: #fff;
}
.social-link:hover .social-icon {
    color: #4a4a4a
}



/* content */
#content {
	min-height: 600px;
}
#content>h2,
#content>h1 {
    margin-top: 0px
}


.catpage-childlist {
    list-style: none;
    margin: 0;
    padding: 0 20px 0 0
}
.catpage-childlist li {
    border-bottom: 1px solid #e6e6e6;
    padding: 4px
}
/* Column-left */
.category-module {
    background: #f5f5f5;
    margin: 0;
    padding: 0;
    list-style: none
}
.category-module__item {
    margin: 0;
    list-style: none;
    padding: 0 20px;
}
.category-module__link {
    font-size: 14px;
    color: #4a4a4a;
    border-top: 1px solid #e6e6e6;
    display: block;
    padding: 10px 0;
}
.category-module__item:first-child .category-module__link {
    border-top: 0
}



/* footer */
footer {
	padding-top: 60px;
    padding-bottom: 40px;
	background-color: #f5f5f5;
	border-top: 4px solid #7dc200;
	color: #4c4c4c;
    margin-top: 60px
}
footer hr {
	border-top: none;
	border-bottom: 1px solid #666;
}
footer a {
	color: #4c4c4c;
    text-transform: uppercase
}
footer a:hover {
	text-decoration: underline
}
footer h5 {
	font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #4c4c4c;
    text-transform: uppercase;
    margin-bottom: 25px;
    margin-top: 0;
}
.copyright {
    background: #f5f5f5;
    text-align: center;
    padding: 8px;
    color: #666;
    font-size: 12px
}
/* alert */
.alert {
	padding: 8px 35px 8px 14px;
}
/* breadcrumb */
.breadcrumb {
	margin: 20px 0;
    padding: 5px 0;
    border-radius: 0;
        background: none;
}
.breadcrumb i {
	font-size: 15px;
}
.breadcrumb > li {
	padding: 0 12px;
	position: relative;
	white-space: nowrap;
}
.breadcrumb > li a {
    color: #999;
    font-size: 13px;
    text-transform: uppercase;
    text-decoration: underline
}
.breadcrumb > li + li:before {
	content: '';
	padding: 0;
}
.breadcrumb > li:after {
	content: '';
	display: block;
	position: absolute;
	top: -4px;
	right: -5px;
	width: 20px;
	height: 20px;
/*	border-right: 1px solid #DDD;*/
	border-bottom: 1px solid #DDD;
	-webkit-transform: rotate(-60deg);
	-moz-transform: rotate(-60deg);
	-o-transform: rotate(-60deg);
	transform: rotate(-60deg);
}
.breadcrumb > li:last-child:after {
    display: none;
}
.breadcrumb > li:last-child a {
    text-decoration: none
}
.pagination {
	margin: 0;
}
/* buttons */
#list-view:focus,
#grid-view:focus {
    outline: none
}
#list-view.btn,
#grid-view.btn {
    border-radius: 0;
    background: #f5f5f5
}
#list-view.btn.active,
#grid-view.btn.active {
    -webkit-box-shadow: 0 0 0;
    box-shadow: 0 0 0 ;
    border: 1px solid #3c98fa;
    color: #3c98fa
}
.buttons {
	margin: 1em 0;
}
.btn {
	padding: 7.5px 12px;
	font-size: 12px;
	border: 1px solid #cccccc;
	border-radius: 0;
	box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
}
.btn-addcart {
    border: 0;
    border-radius: 4px;
    background: #7dc200;
    padding: 10px 25px;
    margin: 10px auto 25px;
    display: block;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
}
.btn-addcart:hover {
    color: #fff;
    background: #7dc200;
		-webkit-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.25);
-moz-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.25);
box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.25);
}
.btn-addcart:hover,
.btn-addcart:focus,
.btn-addcart:active {
	  outline: none;
		color: #fff;
}

.btn-view {
    border: 1px solid #7dc200;
    border-radius: 25px;
    background: #7dc200;
    padding: 15px 25px;
    min-width: 180px;
    margin: 25px auto;
    display: block;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
        max-width: 200px;
}
.btn-view:hover {
    color: #7dc200;
    background: #fff;
    border-color: #7dc200;
    outline: none
}

.btn-addcart:focus,
.btn-addcart:active,
.btn-view:focus,
.btn-view:active  {
    outline: none
}
.btn-xs {
	font-size: 9px;
}
.btn-sm {
	font-size: 10.2px;
}
.btn-lg {
	padding: 10px 16px;
	font-size: 15px;
}
.btn-group > .btn, .btn-group > .dropdown-menu, .btn-group > .popover {
	font-size: 12px;
}
.btn-group > .btn-xs {
	font-size: 9px;
}
.btn-group > .btn-sm {
	font-size: 10.2px;
}
.btn-group > .btn-lg {
	font-size: 15px;
}
.btn-default {
	color: #777;
	text-shadow: 0 0 0;
	background-color: #e7e7e7;
	background-image: none;
	background-repeat: repeat-x;
	border-color: #dddddd ;
}
.btn-primary {
	color: #ffffff;
	text-shadow: 0 0 0;
	background-color: #3c98fa;
	background-image: none;
	background-repeat: repeat-x;
	border-color: #3c98fa;
}
.btn-primary:hover, .btn-primary:active, .btn-primary.active, .btn-primary.disabled, .btn-primary[disabled] {
	background-color: #007bff;
}
.btn-warning {
	color: #ffffff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #faa732;
	background-image: linear-gradient(to bottom, #fbb450, #f89406);
	background-repeat: repeat-x;
	border-color: #f89406 #f89406 #ad6704;
}
.btn-warning:hover, .btn-warning:active, .btn-warning.active, .btn-warning.disabled, .btn-warning[disabled] {
	box-shadow: inset 0 1000px 0 rgba(0, 0, 0, 0.1);
}
.btn-danger {
	color: #ffffff;
	text-shadow: 0 0 0;
	background-color: #d92b2b;
	background-image: none;
	background-repeat: repeat-x;
	border-color: #d92b2b;
}
.btn-danger:hover, .btn-danger:active, .btn-danger.active, .btn-danger.disabled, .btn-danger[disabled] {
	box-shadow: inset 0 0 0;
    background-color: #bf2626
}
.btn-success {
	color: #ffffff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #5bb75b;
	background-image: linear-gradient(to bottom, #62c462, #51a351);
	background-repeat: repeat-x;
	border-color: #51a351 #51a351 #387038;
}
.btn-success:hover, .btn-success:active, .btn-success.active, .btn-success.disabled, .btn-success[disabled] {
	box-shadow: inset 0 1000px 0 rgba(0, 0, 0, 0.1);
}
.btn-info {
	color: #ffffff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #df5c39;
	background-image: linear-gradient(to bottom, #e06342, #dc512c);
	background-repeat: repeat-x;
	border-color: #dc512c #dc512c #a2371a;
}
.btn-info:hover, .btn-info:active, .btn-info.active, .btn-info.disabled, .btn-info[disabled] {
	background-image: none;
	background-color: #df5c39;
}
.btn-link {
	border-color: rgba(0, 0, 0, 0);
	cursor: pointer;
	color: #6ca600;
	border-radius: 0;
}
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
	color: #639900;
	outline: none
}
#compare-total {
    text-transform: uppercase
}
.btn-link, .btn-link:active, .btn-link[disabled] {
	background-color: rgba(0, 0, 0, 0);
	background-image: none;
	box-shadow: none;
}
.btn-inverse {
	color: #ffffff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
	background-color: #363636;
	background-image: linear-gradient(to bottom, #444444, #222222);
	background-repeat: repeat-x;
	border-color: #222222 #222222 #000000;
}
.btn-inverse:hover, .btn-inverse:active, .btn-inverse.active, .btn-inverse.disabled, .btn-inverse[disabled] {
	background-color: #222222;
	background-image: linear-gradient(to bottom, #333333, #111111);
}
#accordion .panel-heading,
#accordion .panel.panel-default {
    border-radius: 0
}
#accordion .panel-heading {
    padding: 8px 15px
}
#accordion .panel-title {
    font-size: 13px
}
#accordion .panel.panel-default {
    border-color: #e5e5e5;
    -webkit-box-shadow: 0 0 0;
    box-shadow: 0 0 0;
}


/* Pagination */
.pagination>li:last-child>a, .pagination>li:last-child>span,
.pagination>li:first-child>a, .pagination>li:first-child>span {
    border-radius: 0
}
.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    background: #3c98fa
}




/* list group */
.list-group a {
	border: 1px solid #DDDDDD;
	color: #888888;
	padding: 8px 12px;
}
.list-group a.active, .list-group a.active:hover, .list-group a:hover {
	color: #444444;
	background: #eeeeee;
	border: 1px solid #DDDDDD;
	text-shadow: 0 1px 0 #FFF;
}
/* carousel */
.carousel-caption {
	color: #FFFFFF;
	text-shadow: 0 1px 0 #000000;
}
.carousel-control .icon-prev:before {
	content: '\f053';
	font-family: FontAwesome;
}
.carousel-control .icon-next:before {
	content: '\f054';
	font-family: FontAwesome;
}


/* Category */
.catpage-list {
    list-style: none;
    margin: 0 0 50px;
    padding: 0
}
.catpage-list li {
    list-style: none;
    display: inline-block;
    float: left;
    width: 49%;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px
}



/* Product */
.price-item {
        color: #4c4c4c;
    font-size: 18px;
    font-weight: bold;
}
#product .btn-addcart {
    width: 100%
}
#input-quantity {
    border-radius: 0;
    -webkit-box-shadow: 0;
    box-shadow: 0;

}
.thumbnail {
    border-radius: 0;
    border-color: #3c98fa
}
.nav-tabs>li>a {
    border-radius: 0
}
.specification-lists {
    margin: 0;
    padding: 0;
    list-style: none;
    /*border: 1px solid #faceaf;
    background: #faebe1*/
}
.specification-list__item {
    padding: 0
}

#content h2.recent-block__title {
    margin-top: 60px
}

#product-related__carusel .image img {
    width: 100%
}

/* quickcheckout  */
.payment-image {
    display: none
}
#d_quickcheckout .qc-step .btn {
    border-radius: 0;
    -webkit-box-shadow: inset 0 0 0;
    box-shadow: inset 0 0 0;
}
#d_quickcheckout  .qc-step .btn-default {
    color: #777;
    text-shadow: 0 0 0;
    background-color: #e7e7e7;
    background-image: none;
    background-repeat: repeat-x;
    border-color: #dddddd;
}
#d_quickcheckout .qc-step .btn-primary {
    color: #ffffff;
    text-shadow: 0 0 0;
    background-color: #3c98fa;
    background-image: none;
    background-repeat: repeat-x;
    border-color: #3c98fa;
}
#d_quickcheckout .qc-step .btn-primary:hover {
    background-color: #007bff;
}
#d_quickcheckout .qc-step .btn-danger {
    color: #ffffff;
    text-shadow: 0 0 0;
    background-color: #d92b2b;
    background-image: none;
    background-repeat: repeat-x;
    border-color: #d92b2b;
}
#d_quickcheckout .qc-step .btn-primary.active {
    background-color: #007bff;
    -webkit-box-shadow: inset 0 0 0;
    box-shadow: inset 0 0 0;
}
#d_quickcheckout .qc-step .panel-heading,
#d_quickcheckout .qc-step .panel{
    border-radius: 0
}
#d_quickcheckout .qc-step .form-control {
    border-radius: 0
}
#d_quickcheckout .qc-step .input-group-sm > .form-control,
#d_quickcheckout .qc-step .input-group-sm > .input-group-addon, #d_quickcheckout .qc-step .input-group-sm > .input-group-btn > .btn {
    border-radius: 0
}
#d_quickcheckout .text-danger,
#d_quickcheckout .has-error .checkbox,
#d_quickcheckout .has-error .checkbox-inline,
#d_quickcheckout .has-error .control-label,
#d_quickcheckout .has-error .help-block,
#d_quickcheckout .has-error .radio,
#d_quickcheckout .has-error .radio-inline,
#d_quickcheckout .has-error.checkbox label,
#d_quickcheckout .has-error.checkbox-inline label,
#d_quickcheckout .has-error.radio label,
#d_quickcheckout .has-error.radio-inline label {
    color: #d92b2b !important;
}
#d_quickcheckout .has-error .form-control {
    border-color: #d92b2b !important;
}



/* product list */
.product-thumb {
	border: 1px solid #ddd;
	margin-bottom: 20px;
	overflow: hidden;
    background: #fff;
    z-index: 100;
    position: relative
}
.com-wish {
    position: absolute;
    display: none;
    top: 7px;
    right: 7px;
    height: 60px;
    width: 100px;
    z-index: 300
}
.product-thumb:hover .com-wish {
    display: block
}
.com-wish button {
    background: #e6e6e6;
    color: #4a4a4a;
    height: 40px;
    width: 40px;
    margin-left: 7px;
    border: none
}
.com-wish button:hover {
    color: #007bff
}
.product-thumb:hover {
    border-color: #9de517;
		-webkit-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.25);
-moz-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.25);
box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.25);
}
.product-thumb .image {
	text-align: center;
}
.product-thumb .image a {
	display: block;
}
.product-thumb .image a:hover {
	opacity: 0.8;
}
.product-thumb .image img {
	margin-left: auto;
	margin-right: auto;
}
.product-grid .product-thumb .image {
	float: none;
}
@media (min-width: 767px) {
.product-list .product-thumb .image {
	float: left;
	padding: 0 15px;
}
}
.product-thumb h4 {
	color: #4a4a4a;
    font-size: 14px;
    text-align: center;
    line-height: 21px;
    text-transform: uppercase;
    height: 80px;
    overflow: hidden
}
.product-thumb h4 a {
    color: #4a4a4a;
}
.product-thumb .caption {
	padding: 0 20px;
	/*min-height: 180px;*/
}
.product-list .product-thumb .caption {
	margin-left: 230px;
}
@media (max-width: 1200px) {
.product-grid .product-thumb .caption {
	min-height: 210px;
	padding: 0 10px;
}
}
@media (max-width: 767px) {
.product-list .product-thumb .caption {
	min-height: 0;
	margin-left: 0;
	padding: 0 10px;
}
.product-grid .product-thumb .caption {
	min-height: 0;
}
}
.product-thumb .rating {
	padding-bottom: 10px;
}
.rating .fa-stack {
	font-size: 8px;
}
.rating .fa-star-o {
	color: #999;
	font-size: 15px;
}
.rating .fa-star {
	color: #FC0;
	font-size: 15px;
}
.rating .fa-star + .fa-star-o {
	color: #E69500;
}
h2.price {
	margin: 0;
}
.product-thumb .price {
	color: #4c4c4c;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}
.product-thumb .price-new {
	font-weight: 600;
}
.product-thumb .price-old {
	color: #ff4200;
	text-decoration: line-through;
	margin-left: 10px;
    font-size: 16px;
    font-weight: normal
}
.product-thumb .price-tax {
	color: #999;
	font-size: 12px;
	display: block;
}
.product-thumb .button-group {
	border-top: 1px solid #ddd;
	background-color: #eee;
	overflow: hidden;
}
.product-list .product-thumb .button-group {
	border-left: 1px solid #ddd;
}
@media (max-width: 768px) {
	.product-list .product-thumb .button-group {
		border-left: none;
	}
}
.product-thumb .button-group button {
	width: 60%;
	border: none;
	display: inline-block;
	float: left;
	background-color: #eee;
	color: #888;
	line-height: 38px;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;
}
.product-thumb .button-group button + button {
	width: 20%;
	border-left: 1px solid #ddd;
}
.product-thumb .button-group button:hover {
	color: #444;
	background-color: #ddd;
	text-decoration: none;
	cursor: pointer;
}
@media (max-width: 1200px) {
	.product-thumb .button-group button, .product-thumb .button-group button + button {
		width: 33.33%;
	}
}
@media (max-width: 767px) {
	.product-thumb .button-group button, .product-thumb .button-group button + button {
		width: 33.33%;
	}
}
.thumbnails {
	overflow: auto;
	clear: both;
	list-style: none;
	padding: 0;
	margin: 0;
}
.thumbnails > li {
	margin-left: 20px;
}
.thumbnails {
	margin-left: -20px;
}
.thumbnails > img {
	width: 100%;
}
.image-additional a {
	margin-bottom: 20px;
	padding: 5px;
	display: block;
	border: 1px solid #ddd;
}
.image-additional {
	max-width: 78px;
}
.thumbnails .image-additional {
	float: left;
	margin-left: 20px;
}

@media (min-width: 1200px) {
	#content .col-lg-2:nth-child(6n+1),
	#content .col-lg-3:nth-child(4n+1),
	#content .col-lg-4:nth-child(3n+1),
	#content .col-lg-6:nth-child(2n+1) {
		clear:left;
	}
}
@media (min-width: 992px) and (max-width: 1199px) {
	#content .col-md-2:nth-child(6n+1),
	#content .col-md-3:nth-child(4n+1),
	#content .col-md-4:nth-child(3n+1),
	#content .col-md-6:nth-child(2n+1) {
		clear:left;
	}
}
@media (min-width: 768px) and (max-width: 991px) {
	#content .col-sm-2:nth-child(6n+1),
	#content .col-sm-3:nth-child(4n+1),
	#content .col-sm-4:nth-child(3n+1),
	#content .col-sm-6:nth-child(2n+1) {
		clear:left;
	}
}

/* fixed colum left + content + right*/
@media (min-width: 768px) {
    #column-left  .product-layout .col-md-3 {
       width: 100%;
    }

	#column-left + #content .product-layout .col-md-3 {
       width: 50%;
    }

	#column-left + #content + #column-right .product-layout .col-md-3 {
       width: 100%;
    }

    #content + #column-right .product-layout .col-md-3 {
       width: 100%;
    }
}

/* fixed product layouts used in left and right columns */
#column-left .product-layout, #column-right .product-layout {
	width: 100%;
}

/* fixed mobile cart quantity input */
.input-group .form-control[name^=quantity] {
	min-width: 50px;
    border-radius: 0
}

/* Missing focus and border color to overwrite bootstrap */
.btn-info:hover, .btn-info:active, .btn-info.active, .btn-info.disabled, .btn-info[disabled] {
    background-image: none;
    background-color: #df5c39;
}

/* Animate alert box */
#alert-box {
	position: fixed;
	top: 15px;
	transition: all 0.5s;
	z-index: 99;
	right: -50%;
}

#alert-box.open {
	right: 15px;
}

@media screen and (max-width: 767px){
	#alert-box {
		padding: 15px;
	}
	#alert-box.open {
		right: 0px;
	}
}

/* fixed alert-dismissable close position */
.alert-dismissable .close,
.alert-dismissible .close {
	right: -4px;
}
.brand-link__img {
    border: 1px solid #dfdfdf;
    padding: 15px;
        display: block;
}
.brand-link__img img {
    width: 100%
}
a.brand-link__txt {
    display: block;
    text-align: center;
    text-transform: uppercase;
    margin: 10px auto 30px
}
.back-to-top {
    background: rgba(255, 255, 255, .5);
    border: 1px solid #007bff;
    color: #007bff !important;
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    right: 20px;
    min-width: 43px;
    height: 36px;
    display: none;
    transition: all .3s ease-out;
    z-index: 999999;
}
.back-to-top:hover,
.back-to-top:focus {
    background:#007bff;
    color: #fff !important;
    border-color: #007bff;
}


.price-status .action {
    position: absolute;
    left: 0;
    border-top: 67px solid #ff4200;
    width: 0;
    height: 0;
    top: 0;
    border-right: 67px solid transparent;
}
.price-status .caption-price-status {
    position: absolute;
    top: -55px;
    right: -40px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    font-size: 11px;
    font-weight: bold;
    color: #FFF;
    display: block;
    white-space: nowrap;
}
.product_img .hit-status .action {
	    right: 15px;
}
.product_img .new-status .action {
	  left: 15px;
    bottom: 20px;
		top: auto;
}
.new-status .action {
    position: absolute;
    left: 0;
    /* border-top: 67px solid #007bff; */
    width: 120px;
    height: 30px;
		background: #007bff;
		top: 230px;
    text-align: center;
    padding: 5px;
    /* border-left: 67px solid transparent; */
}

.new-status .caption-new-status {
    /* position: absolute;
		top: -50px;
    left: -50px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg); */
    font-size: 11px;
    font-weight: bold;
    color: #FFF;
    display: block;
    white-space: nowrap;
}

.hit-status .action {
    position: absolute;
    right: 0;
    border-top: 67px solid #ff8a00;
    width: 0;
    height: 0;
    top: 0;
    border-left: 67px solid transparent;
}
.hit-status .caption-hit-status {
    position: absolute;
		top: -55px;
    left: -35px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    font-size: 11px;
    font-weight: bold;
    color: #FFF;
    display: block;
    white-space: nowrap;
}
.product_img .price-status .action {
    left: 15px
}
