@import url('https://fonts.googleapis.com/css?family=Noto+Serif:400,700&amp;subset=latin-ext');

@import 'var';
body {
    font-family: $font-base;
    font-weight: 400;
    color: $color-main;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
    background: #fff;
}


/* default font size */

.fa {
    font-size: 14px;
}


/* Override the bootstrap defaults */

h1 {
    font-size: 33px;
}

h2 {
    font-size: 27px;
}

h3 {
    font-size: 21px;
}

h4 {
    font-size: 15px;
}

h5 {
    font-size: 12px;
}

h6 {
    font-size: 10.2px;
}

a {
    color: $color-link;
}

a:hover {
    text-decoration: none;
    color: $color-link;
    outline: none
}

a:focus,
a:active {
    color: $color-link;
    outline: none
}

legend {
    font-size: 18px;
    padding: 7px 0px
}

label {
    font-size: 12px;
    font-weight: normal;
}

select.form-control,
textarea.form-control,
input[type="text"].form-control,
input[type="password"].form-control,
input[type="datetime"].form-control,
input[type="datetime-local"].form-control,
input[type="date"].form-control,
input[type="month"].form-control,
input[type="time"].form-control,
input[type="week"].form-control,
input[type="number"].form-control,
input[type="email"].form-control,
input[type="url"].form-control,
input[type="search"].form-control,
input[type="tel"].form-control,
input[type="color"].form-control {
    font-size: 12px;
    border-radius: 0;
    -webkit-box-shadow: inset 0 0 0;
    box-shadow: inset 0 0 0;
}

.input-group input,
.input-group select,
.input-group .dropdown-menu,
.input-group .popover {
    font-size: 12px;
}

.input-group .input-group-addon {
    font-size: 12px;
    height: 30px;
    border-radius: 0
}

#input-sort,
#input-limit {
    border-radius: 0;
}

#content .well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 0;
    -webkit-box-shadow: inset 0 0 0;
    box-shadow: inset 0 0 0;
}

.container {
    overflow: hidden
}


/* Fix some bootstrap issues */

span.hidden-xs,
span.hidden-sm,
span.hidden-md,
span.hidden-lg {
    display: inline;
}

.list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.nav-tabs {
    margin-bottom: 15px;
}

div.required .control-label:before {
    content: '* ';
    color: #F00;
    font-weight: bold;
}


/* Gradent to all drop down menus */

.dropdown-menu li>a:hover {
    text-decoration: none;
    color: $color-white !important;
    background-color: #0071eb;
    background-image: none;
    background-repeat: repeat-x;
}

#header.fix-header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

/* top */

#top {
    background: $color-white;
    margin: 0 0 15px 0;
    min-height: 40px;
    color: $color-main;
}

#top .container {
    padding: 0 20px;
    overflow: visible
}

.top-adres {
    margin-top: 5px;
    font-size: 12px;
    text-transform: uppercase;
}

#top #form-currency .currency-select,
#top #form-language .language-select {
    text-align: left;
}

#top #form-currency .currency-select:hover,
#top #form-language .language-select:hover {
    text-shadow: none;
    color: $color-white;
    background-color: #229ac8;
    background-image: linear-gradient(to bottom, #23a1d1, #1f90bb);
    background-repeat: repeat-x;
}

.language-block .dropdown-menu {
    border-radius: 0;
    left: -31px;
    margin-top: 0
}
.flexblock {
  display: flex;
  justify-content: space-between;
}
.language-list {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    margin: 0;
    padding: 0;
    list-style: none;
    .language-link {
      color: #fff;
      font-size: 12px;
    }
  }
}

#top .btn-link,
#top-links li,
#top-links a {
    text-decoration: none;
    text-align: left;
}

#top .btn-link:hover,
#top-links a:hover {
    color: #444;
}

#top-links .dropdown-menu a {
    text-shadow: none;
}

#top-links .dropdown-menu a:hover {
    color: #FFF;
}

#top .btn-link strong {
    font-size: 14px;
    line-height: 14px;
}

#top-links {
    padding-top: 6px;
}

#top-links a+a {
    margin-left: 15px;
}

.dop-menu {
    list-style: none;
    margin: 0;
    padding: 0
}

.dop-menu__item {
    display: inline-block;
    float: left;
    margin: 0 2em;
    padding-top: 15px;
}

.dop-menu__link {
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
}

.dop-menu__link:hover {
    color: #4a4a4a;
    text-decoration: none
}


/* Language */

.top-user-block {
    padding-top: 5px
}

.login {
    font-size: 14px;
    margin-top: 13px;
    .login__link {
      color: $color-link;
      font-size: 14px;
      font-style: italic;
      text-decoration: none;
      margin-left: 30px;
      padding-left: 12px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 6px;
        width: 6px;
        height: 6px;
        background: $color-link;
        border-radius: 50%;
      }
    }
}

.top__right {
    display: flex;
    justify-content: flex-end;
    .search__box {
        max-width: 32px;
        height: 32px;
        margin-right: 15px;
        margin-left: 20px;
        margin-top: 13px;
        border: 1px solid $color-link;
        border-radius: 25px;
        transition: all 1s ease-out;
        overflow: hidden;
        position: relative;
        z-index: 50;
        &:after {
          content: '\f002';
          position: absolute;
          right: 5px;
          top: 5px;
          font-size: 18px;
          font-family: FontAwesome;
          font-style: normal;
          font-weight: normal;
          text-decoration: inherit;
        }
        &:hover {
          max-width: 310px;
          transition: all 1s ease-out;
          .btn-lg {
            transition: all 1s ease-out;
            transform: translateX(0);
          }
          .input-lg {
            transition: all 1s ease-out;
            transform: translateX(0);
          }
        }
        .input-lg {
            height: 30px;
            line-height: 20px;
            padding: 0 10px;
            border: 0;
            box-shadow: none;
            transition: all 1s ease-out;
            transform: translateX(300px);
        }
        .btn-lg {
            height: 32px;
            width: 32px;
            text-shadow: none;
            background: transparent;
            border: 0;
            transform: translateX(300px);
            transition: all 1s ease-out;
        }
    }
}

.language-block {
    text-align: left;
    margin-top: 16px;
}

.language-block span {
    text-transform: uppercase;
    margin: 0 5px;
}

.language-block img {
    margin-right: 8px;
}

.language-block button,
.language-block button:hover {
    color: $color-main !important;
}




/* logo */

#logo {
    font-family: $font-title;
    font-weight: bold;
    font-size: 24px;
    text-transform: uppercase;
    margin-top: 15px;
    a {
        display: flex;
    }
    span {
        font-size: 14px;
    }
    img {
        margin-right: 8px;
        height: auto;
    }
}

#header {
    padding-bottom: 10px;
    background: $color-main;
    color: $color-white;
}


/*** Menu   ***/

#menu.navbar {
    min-height: 30px;
    margin-top: 20px;
    .flex-fill {
        display: flex;
        flex: 1;
        justify-content: center;
        float: none;
    }
    .navbar-nav>li>a {
        padding: 5px 15px;
        color: $color-white;
        font-size: 16px;
        font-style: italic;
        &:hover,
        &:focus {
            text-decoration: underline;
            background-color: transparent;
            opacity: 1;
        }
    }
}


/* cart */

#cart {
    width: auto;
    margin-top: 17px;
    .btn-top-cart {
        background: transparent;
        border: 0;
        font-size: 14px;
        line-height: 21px;
        max-width: 260px;
        color: $color-main;
        position: relative;
        box-shadow: none;
        padding: 0;
        &:before {
            content: '\f07a';
            position: absolute;
            height: 24px;
            width: 24px;
            top: 0;
            left: 0;
            color: $color-main;
            font-family: FontAwesome;
            font-style: normal;
            font-weight: normal;
            text-decoration: inherit;
            font-size: 16px;
            line-height: 21px
        }
    }
}

span#cart-total {
    margin-left: 30px;
    font-weight: bold;
}

#cart .dropdown-menu {
    background: #eee;
    z-index: 1001;
}

#cart .dropdown-menu {
    min-width: 100%;
}

@media (max-width: 478px) {
    #cart .dropdown-menu {
        width: 100%;
    }
}

#cart .dropdown-menu table {
    margin-bottom: 10px;
}

#cart .dropdown-menu li>div {
    min-width: 427px;
    padding: 0 10px;
}

@media (max-width: 478px) {
    #cart .dropdown-menu li>div {
        min-width: 100%;
    }
}

#cart .dropdown-menu li p {
    margin: 20px 0;
}


/* menu */

#menu.navbar {
    margin-bottom: 0
}

.main-navigation {
    background: #7dc200
}

.main-navigation .container,
header .container {
    overflow: visible
}



#menu .navbar-collapse {
    padding-left: 0;
    padding-right: 0
}


#menu .dropdown-menu {
    padding: 0;
    background: #f5f5f5;
    border-radius: 0;
    width: 100%
}

#menu .dropdown-inner {
    display: table;
    width: 100%
}

#menu .dropdown-inner ul {
    display: table-cell;
}

#menu .dropdown-inner a {
    font-size: 14px;
    color: #4a4a4a;
    border-top: 1px solid #e6e6e6;
    display: block;
    padding: 10px 0;
}

#menu .dropdown-menu li>a:hover {
    text-decoration: none;
    color: #74b300 !important;
    background-color: transparent;
    background-image: none;
    background-repeat: no-repeat;
}

#menu .dropdown-inner li a:hover {
    color: #FFFFFF;
}

#menu .see-all {
    display: block;
    margin-top: 0.5em;
    border-top: 1px solid #DDD;
    padding: 3px 20px;
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    border-radius: 0 0 3px 3px;
    font-size: 12px;
}

#menu .see-all:hover,
#menu .see-all:focus {
    text-decoration: none;
    color: #ffffff;
    background-color: #229ac8;
    background-image: linear-gradient(to bottom, #23a1d1, #1f90bb);
    background-repeat: repeat-x;
}

#menu #category {
    float: left;
    padding-left: 15px;
    font-size: 16px;
    font-weight: 700;
    line-height: 40px;
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
}

#menu .btn-navbar {
    font-size: 15px;
    font-stretch: expanded;
    color: #FFF;
    padding: 2px 18px;
    float: right;
    background-color: #229ac8;
    background-image: linear-gradient(to bottom, #23a1d1, #1f90bb);
    background-repeat: repeat-x;
    border-color: #1f90bb #1f90bb #145e7a;
}

#menu .btn-navbar:hover,
#menu .btn-navbar:focus,
#menu .btn-navbar:active,
#menu .btn-navbar.disabled,
#menu .btn-navbar[disabled] {
    color: #ffffff;
    background-color: #229ac8;
}

@media (max-width: 1199px) {
    .home-advantage__item {
        text-align: center !important;
        width: 32% !important;
    }
    .home-advantage__txt {
        margin-left: 0 !important;
    }
    .home-advantage__icon {
        float: none !important;
        display: block !important;
    }
}

@media (min-width: 768px) {
    #menu .dropdown:hover .dropdown-menu {
        display: block;
    }
}



 /*** Home Hero ***/
 .section-hero {
   padding: 90px 0;
   background: url(../../../../../image/catalog/hero/home-hero.jpg) top center no-repeat;
   background-size: cover;
   background-attachment: fixed;
   h1 {
     font-size: 72px;
     color: $color-white;
     font-weight: bold;
     text-transform: uppercase;
     display: inline-block;
     background: $color-second;
     padding: 10px 40px;
     margin: 50px 0;
   }
 }

/* Slider */

.home-slider {
    margin-top: 60px;
    .swiper-viewport {
      margin: 0;
    }
}



.home-block__title {
    margin: 0 0 45px;
    color: #4c4c4c;
    font-weight: bold;
    text-transform: uppercase;
    padding-bottom: 15px;
    text-align: center;
}

.brands_products {
  background: $color-grey-bg;
  padding: 60px 0;
  margin-top: 60px;
  .brands__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    .brands__item {
      list-style: none;
      padding: 0;
      margin: 0;
      width: 14%;
    }
  }
}


/* Bestseller */

.home-bestseller {
    padding: 90px 0 65px;
    overflow: hidden;
    .swiper-container {
        padding-bottom: 25px;
    }
    .swiper-pager {
        top: 0;
        bottom: 0;
        height: 100%;
        .swiper-button-next,
        .swiper-button-prev {
            top: 22px;
            bottom: 40px;
            width: 200px;
            height: auto;
            background: rgba(255, 255, 255, 0.7);
            background-image: none;
            &::before {
                content: '';
                display: none;
            }
            &:hover {
                .arrow {
                    display: block;
                }
            }
            .arrow {
                position: relative;
                display: none;
                top: 45%;
                width: 64px;
                height: 64px;
                margin-left: -32px;
            }
        }
        .swiper-button-next {
            right: 50px;
        }
        .swiper-button-prev {
            left: 50px;
        }
        .arrow--right {
            left: 50%;
            background: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCA0MDcuNDM2IDQwNy40MzYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQwNy40MzYgNDA3LjQzNjsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSI2NHB4IiBoZWlnaHQ9IjY0cHgiPgo8cG9seWdvbiBwb2ludHM9IjExMi44MTQsMCA5MS41NjYsMjEuMTc4IDI3My41MTIsMjAzLjcxOCA5MS41NjYsMzg2LjI1OCAxMTIuODE0LDQwNy40MzYgMzE1Ljg2OSwyMDMuNzE4ICIgZmlsbD0iIzRhNGE0YSIvPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K) top left no-repeat;
        }
        .arrow--left {
            left: 50%;
            background: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCA0MDcuNDM2IDQwNy40MzYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQwNy40MzYgNDA3LjQzNjsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSI2NHB4IiBoZWlnaHQ9IjY0cHgiPgo8cG9seWdvbiBwb2ludHM9IjMxNS44NjksMjEuMTc4IDI5NC42MjEsMCA5MS41NjYsMjAzLjcxOCAyOTQuNjIxLDQwNy40MzYgMzE1Ljg2OSwzODYuMjU4IDEzMy45MjQsMjAzLjcxOCAiIGZpbGw9IiM0YTRhNGEiLz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==) top left no-repeat;
        }
    }
    .swiper-pagination.home-bestseller__carusel {
        display: none;
    }
}

// .home-bestseller .swiper-container {
//     padding-bottom: 40px
// }
// .home-bestseller .swiper-container-horizontal .swiper-pagination-bullets {
//     bottom: 10px
// }

/* Latest */

.home-latest {
    padding: 50px 0 65px;
    overflow: hidden;
    .swiper-container {
        padding-bottom: 25px;
    }
    .swiper-pager {
        top: 0;
        bottom: 0;
        height: 100%;
        .swiper-button-next,
        .swiper-button-prev {
            top: 0;
            bottom: 0;
            width: 200px;
            height: 100%;
            background: rgba(255, 255, 255, 0.7);
            background-image: none;
            &::before {
                content: '';
                display: none;
            }
            &:hover {
                .arrow {
                    display: block;
                }
            }
            .arrow {
                position: relative;
                display: none;
                top: 45%;
                width: 64px;
                height: 64px;
                margin-left: -32px;
            }
        }
        .swiper-button-next {
            right: 50px;
        }
        .swiper-button-prev {
            left: 50px;
        }
        .arrow--right {
            left: 50%;
            background: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCA0MDcuNDM2IDQwNy40MzYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQwNy40MzYgNDA3LjQzNjsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSI2NHB4IiBoZWlnaHQ9IjY0cHgiPgo8cG9seWdvbiBwb2ludHM9IjExMi44MTQsMCA5MS41NjYsMjEuMTc4IDI3My41MTIsMjAzLjcxOCA5MS41NjYsMzg2LjI1OCAxMTIuODE0LDQwNy40MzYgMzE1Ljg2OSwyMDMuNzE4ICIgZmlsbD0iIzRhNGE0YSIvPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K) top left no-repeat;
        }
        .arrow--left {
            left: 50%;
            background: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCA0MDcuNDM2IDQwNy40MzYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQwNy40MzYgNDA3LjQzNjsiIHhtbDpzcGFjZT0icHJlc2VydmUiIHdpZHRoPSI2NHB4IiBoZWlnaHQ9IjY0cHgiPgo8cG9seWdvbiBwb2ludHM9IjMxNS44NjksMjEuMTc4IDI5NC42MjEsMCA5MS41NjYsMjAzLjcxOCAyOTQuNjIxLDQwNy40MzYgMzE1Ljg2OSwzODYuMjU4IDEzMy45MjQsMjAzLjcxOCAiIGZpbGw9IiM0YTRhNGEiLz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==) top left no-repeat;
        }
    }
    .swiper-pagination.home-latest__carusel {
        display: none;
    }
}

.carusel_margin {
    margin: 0 -50px;
}

// .home-latest .swiper-container {
//     padding-bottom: 40px
// }
// .home-latest .swiper-container-horizontal .swiper-pagination-bullets {
//     bottom: 10px
// }

/*** Carousel Home ***/

.home-carousel {
    background: $color-light-grey-bg;
    padding: 90px 0;
    .swiper-pagination {
        display: none;
    }
    .swiper-viewport {
        margin: 0;
        background: transparent
    }
}


.home-featured {
  padding: 60px 0;
}


/*** Home Hero ***/
.section-worktime {
  padding: 90px 0;
  margin-top: 90px;
  background: url(../../../../../image/catalog/facebook/facebook-bg.jpg) top center no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.worktime {
  background: $color-main;
  border-radius: 50%;
  padding: 10px;
  width: 200px;
  height: 200px;
  text-align: center;
  margin: 0 auto;
  .worktime__circle {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    padding-top: 50px;
    border: 1px solid $color-white;
    .worktime__title {
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
      color: $color-white;
      margin-bottom: 10px;
    }
    .worktime__day {
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
      color: $color-white;
      margin-bottom: 10px;
    }
    .worktime__time {
      font-size: 24px;
      font-weight: bold;
      text-transform: uppercase;
      color: $color-white;
    }
  }
}


/* Social */
.social {
  margin-top: 17px;
      margin-left: 15px;
}
.social-items {
  margin: 8px 0;
  padding: 0;
  list-style: none;
  text-align: right;
  .social-item {
    display: inline-block;
    margin-right: 10px;
    .social-icon {
      text-align: center;
      color: $color-white;
      .fa {
        font-size: 18px;
      }
      &:hover {
        color: $color-hover;
      }
    }

  }
}

footer {
  .social {
    margin-top: 0;
    .social-items {
      .social-item {
        .social-icon {
            text-align: center;
            color: $color-main;
            .fa {
              font-size: 36px;
            }
        }
        .social-link:hover .social-icon {
            color: $color-hover;
        }
      }
    }
  }
}


/* content */

#content {
    min-height: 600px;
}

#content>h2,
#content>h1 {
    margin-top: 0px
}

// .catpage-childlist {
//     list-style: none;
//     margin: 0;
//     padding: 0 20px 0 0
// }
//
// .catpage-childlist li {
//     border-bottom: 1px solid #e6e6e6;
//     padding: 4px
// }


/* Column-left */

.category-module {
    background: #f5f5f5;
    margin: 0;
    padding: 0;
    list-style: none
}

.category-module__item {
    margin: 0;
    list-style: none;
    padding: 0 20px;
}

.category-module__link {
    font-size: 14px;
    color: #4a4a4a;
    border-top: 1px solid #e6e6e6;
    display: block;
    padding: 10px 0;
}

.category-module__item:first-child .category-module__link {
    border-top: 0
}





/* footer */

footer {
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #f5f5f5;
    .copyright {
        margin-top: 15px;
        font-size: 12px;
    }
    hr {
        border-top: none;
        border-bottom: 1px solid #666;
    }
    .list-unstyled {
        a {
            line-height: 24px;
            color: #4c4c4c;
            text-transform: uppercase;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    h5 {
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        font-weight: bold;
        color: #4c4c4c;
        text-transform: uppercase;
        margin-bottom: 25px;
        margin-top: 0;
    }
}


/* alert */

.alert {
    padding: 8px 35px 8px 14px;
}


/* breadcrumb */

.breadcrumb {
    margin: 20px 0;
    padding: 5px 0;
    border-radius: 0;
    background: none;
}

.breadcrumb i {
    font-size: 15px;
}

.breadcrumb>li {
    padding: 0 12px;
    position: relative;
    white-space: nowrap;
}

.breadcrumb>li a {
    color: #999;
    font-size: 13px;
    text-transform: uppercase;
    text-decoration: underline
}

.breadcrumb>li+li:before {
    content: '';
    padding: 0;
}

.breadcrumb>li:after {
    content: '';
    display: block;
    position: absolute;
    top: -4px;
    right: -5px;
    width: 20px;
    height: 20px;
    /*	border-right: 1px solid #DDD;*/
    border-bottom: 1px solid #DDD;
    -webkit-transform: rotate(-60deg);
    -moz-transform: rotate(-60deg);
    -o-transform: rotate(-60deg);
    transform: rotate(-60deg);
}

.breadcrumb>li:last-child:after {
    display: none;
}

.breadcrumb>li:last-child a {
    text-decoration: none
}

.pagination {
    margin: 0;
}


/* buttons */

#list-view:focus,
#grid-view:focus {
    outline: none
}

#list-view.btn,
#grid-view.btn {
    border-radius: 0;
    background: #f5f5f5
}

#list-view.btn.active,
#grid-view.btn.active {
    -webkit-box-shadow: 0 0 0;
    box-shadow: 0 0 0;
    border: 1px solid $color-link;
    color: $color-link;
}
.btn-link {
  color: $color-link;
  text-decoration: none;
}
.btn-link:focus, .btn-link:hover {
  color: $color-link;
}
.buttons {
    margin: 1em 0;
}

.btn {
    padding: 7.5px 12px;
    font-size: 12px;
    border: 1px solid #cccccc;
    border-radius: 0;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
}
.btn-addcart {
  max-width: 230px;
}
.btn-primary {
  max-width: 300px;
}
.btn-addcart,
.btn-primary {
    border: 1px solid $color-link;
    border-radius: 25px;
    background: $color-white;
    padding: 10px 25px;
    margin: 8px auto;
    display: block;
    color: $color-link;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    &:hover {
        color: $color-white;
        background: $color-hover;
        border: 1px solid $color-hover;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, .25);
    }
}

.btn-addcart:hover,
.btn-addcart:focus,
.btn-addcart:active,
.btn-addcart:active:focus,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:active:focus {
    outline: none;
    color: $color-white;
    background: $color-hover;
}

.btn-addcart--click {
    opacity: 0.8;
    &:hover {
        opacity: 1;
    }
}

.btn-view {
    border: 1px solid #7dc200;
    border-radius: 25px;
    background: #7dc200;
    padding: 15px 25px;
    min-width: 180px;
    margin: 25px auto;
    display: block;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    max-width: 200px;
}

.btn-view:hover {
    color: #7dc200;
    background: #fff;
    border-color: #7dc200;
    outline: none
}

.btn-addcart:focus,
.btn-addcart:active,
.btn-view:focus,
.btn-view:active {
    outline: none
}

.btn-xs {
    font-size: 9px;
}

.btn-sm {
    font-size: 10.2px;
}

.btn-lg {
    padding: 10px 16px;
    font-size: 15px;
}

.btn-group>.btn,
.btn-group>.dropdown-menu,
.btn-group>.popover {
    font-size: 12px;
}

.btn-group>.btn-xs {
    font-size: 9px;
}

.btn-group>.btn-sm {
    font-size: 10.2px;
}

.btn-group>.btn-lg {
    font-size: 15px;
}

.btn-default {
    color: #777;
    text-shadow: 0 0 0;
    background-color: #e7e7e7;
    background-image: none;
    background-repeat: repeat-x;
    border-color: #dddddd;
}
#modal-cart .modal-footer .btn-default,
.pull-left .btn-default {
  border: 1px solid $color-link;
  border-radius: 25px;
  background: $color-white;
  padding: 10px 25px;
  margin: 8px auto;
  display: block;
  color: $color-link;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  &:hover {
      color: $color-link;
      background: #dfdfdf;
      border: 1px solid $color-link;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, .25);
  }
}

#d_quickcheckout #login_view .login-btn-group .btn {
  white-space: normal;
  text-transform: lowercase;
  height: 51px;
}

.btn-warning {
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #faa732;
    background-image: linear-gradient(to bottom, #fbb450, #f89406);
    background-repeat: repeat-x;
    border-color: #f89406 #f89406 #ad6704;
}

.btn-warning:hover,
.btn-warning:active,
.btn-warning.active,
.btn-warning.disabled,
.btn-warning[disabled] {
    box-shadow: inset 0 1000px 0 rgba(0, 0, 0, 0.1);
}

.btn-danger {
    color: #ffffff;
    text-shadow: 0 0 0;
    background-color: #d92b2b;
    background-image: none;
    background-repeat: repeat-x;
    border-color: #d92b2b;
}

.btn-danger:hover,
.btn-danger:active,
.btn-danger.active,
.btn-danger.disabled,
.btn-danger[disabled] {
    box-shadow: inset 0 0 0;
    background-color: #bf2626
}

.btn-success {
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #5bb75b;
    background-image: linear-gradient(to bottom, #62c462, #51a351);
    background-repeat: repeat-x;
    border-color: #51a351 #51a351 #387038;
}

.btn-success:hover,
.btn-success:active,
.btn-success.active,
.btn-success.disabled,
.btn-success[disabled] {
    box-shadow: inset 0 1000px 0 rgba(0, 0, 0, 0.1);
}

.btn-info {
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #df5c39;
    background-image: linear-gradient(to bottom, #e06342, #dc512c);
    background-repeat: repeat-x;
    border-color: #dc512c #dc512c #a2371a;
}

.btn-info:hover,
.btn-info:active,
.btn-info.active,
.btn-info.disabled,
.btn-info[disabled] {
    background-image: none;
    background-color: #df5c39;
}

.btn-link {
    border-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    border-radius: 0;
}

.btn-link:hover,
.btn-link:focus,
.btn-link:active {
    outline: none
}
.increase {
  width: 36px;
}
#compare-total {
    text-transform: uppercase
}

.btn-link,
.btn-link:active,
.btn-link[disabled] {
    background-color: rgba(0, 0, 0, 0);
    background-image: none;
    box-shadow: none;
}

.btn-inverse {
    color: #ffffff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #363636;
    background-image: linear-gradient(to bottom, #444444, #222222);
    background-repeat: repeat-x;
    border-color: #222222 #222222 #000000;
}

.btn-inverse:hover,
.btn-inverse:active,
.btn-inverse.active,
.btn-inverse.disabled,
.btn-inverse[disabled] {
    background-color: #222222;
    background-image: linear-gradient(to bottom, #333333, #111111);
}
.news-list__title {
  margin-bottom: 5px;
  a {
    font-size: 18px;
    font-weight: bold;
  }
}
.news-list__date {
  opacity: 0.8;
  font-size: 12px;
  margin-bottom: 5px;
}
.news-list__link {
  margin-top: 8px;
  a {
    text-decoration: underline;
  }
}
#accordion .panel-heading,
#accordion .panel.panel-default {
    border-radius: 0
}

#accordion .panel-heading {
    padding: 8px 15px
}

#accordion .panel-title {
    font-size: 13px
}

#accordion .panel.panel-default {
    border-color: #e5e5e5;
    -webkit-box-shadow: 0 0 0;
    box-shadow: 0 0 0;
}


/* Pagination */

.pagination>li:last-child>a,
.pagination>li:last-child>span,
.pagination>li:first-child>a,
.pagination>li:first-child>span {
    border-radius: 0
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
    background: #3c98fa
}




/* list group */

.list-group a {
    border: 1px solid #DDDDDD;
    color: #888888;
    padding: 8px 12px;
}

.list-group a.active,
.list-group a.active:hover,
.list-group a:hover {
    color: #444444;
    background: #eeeeee;
    border: 1px solid #DDDDDD;
    text-shadow: 0 1px 0 #FFF;
}


/* carousel */

.carousel-caption {
    color: #FFFFFF;
    text-shadow: 0 1px 0 #000000;
}

.carousel-control .icon-prev:before {
    content: '\f053';
    font-family: FontAwesome;
}

.carousel-control .icon-next:before {
    content: '\f054';
    font-family: FontAwesome;
}


/* Category */
.catpage-childlist,
.catpage-list {
    list-style: none;
    margin: 0 0 50px;
    padding: 0;
}

.catpage-childlist li,
.catpage-list li {
    list-style: none;
    display: inline-block;
    float: left;
    width: 33%;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    margin-bottom: 15px;
    padding: 0 15px;
    box-sizing: border-box;
    img {
      margin-bottom: 10px;
      width: 100%;
      border: 1px solid #e5e5e5;
      border-radius: 0;
    }
    .catpage__title {
      text-align: center;
      height: 40px;
      overflow: hidden;
      display: block;
    }
    &:hover {
      img {
        border-color: $color-hover;
      }
    }
}


/* Product */

.price-item {
    color: #4c4c4c;
    font-size: 18px;
    font-weight: bold;
}

#product .btn-addcart {
    width: 100%;
    display: inline-block;
}

#input-quantity {
    border-radius: 0;
    -webkit-box-shadow: 0;
    box-shadow: 0;
}

.thumbnail {
    border-radius: 0;
}

.nav-tabs>li>a {
    border-radius: 0
}

.specification-lists {
    margin: 0;
    padding: 0;
    list-style: none;
    /*border: 1px solid #faceaf;
    background: #faebe1*/
}

.specification-list__item {
    padding: 0
}

#content h2.recent-block__title {
    margin-top: 60px
}

#product-related__carusel .image img {
    width: 100%
}


/* quickcheckout  */

.payment-image {
    display: none
}
#d_quickcheckout .btn-primary {
  text-transform: none;
}
#d_quickcheckout #qc_confirm_order {
  border: 1px solid $color-link;
  border-radius: 25px;
  background: $color-white;
  padding: 10px 25px;
  margin: 8px auto;
  display: block;
  color: $color-link;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  float: right;
  &:hover {
      color: $color-white;
      background: $color-hover;
      border: 1px solid $color-hover;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, .25);
  }
}
#d_quickcheckout .btn-primary:hover, #d_quickcheckout .btn-primary:active, #d_quickcheckout .btn-primary.active, #d_quickcheckout .btn-primary.disabled, #d_quickcheckout .btn-primary[disabled] {
    background-color: $color-link;
    background-position: 0 -15px;
}
#d_quickcheckout .qc-step .btn {
    border-radius: 0;
    -webkit-box-shadow: inset 0 0 0;
    box-shadow: inset 0 0 0;
}

#d_quickcheckout .qc-step .btn-default {
    color: #777;
    text-shadow: 0 0 0;
    background-color: #e7e7e7;
    background-image: none;
    background-repeat: repeat-x;
    border-color: #dddddd;
}

#d_quickcheckout .qc-step .btn-primary {
    color: #ffffff;
    text-shadow: 0 0 0;
    background-color: $color-link;
    background-image: none;
    background-repeat: repeat-x;
    border-color: $color-link;
}

#d_quickcheckout .qc-step .btn-primary:hover {
    background-color: $color-link;
}

#d_quickcheckout .qc-step .btn-danger {
    color: #ffffff;
    text-shadow: 0 0 0;
    background-color: $color-second;
    background-image: none;
    background-repeat: repeat-x;
    border-color: $color-second;
}

#d_quickcheckout .qc-step .btn-primary.active {
    background-color: $color-link;
    -webkit-box-shadow: inset 0 0 0;
    box-shadow: inset 0 0 0;
}

#d_quickcheckout .qc-step .panel-heading,
#d_quickcheckout .qc-step .panel {
    border-radius: 0
}

#d_quickcheckout .qc-step .form-control {
    border-radius: 0
}

#d_quickcheckout .qc-step .input-group-sm>.form-control,
#d_quickcheckout .qc-step .input-group-sm>.input-group-addon,
#d_quickcheckout .qc-step .input-group-sm>.input-group-btn>.btn {
    border-radius: 0
}

#d_quickcheckout .text-danger,
#d_quickcheckout .has-error .checkbox,
#d_quickcheckout .has-error .checkbox-inline,
#d_quickcheckout .has-error .control-label,
#d_quickcheckout .has-error .help-block,
#d_quickcheckout .has-error .radio,
#d_quickcheckout .has-error .radio-inline,
#d_quickcheckout .has-error.checkbox label,
#d_quickcheckout .has-error.checkbox-inline label,
#d_quickcheckout .has-error.radio label,
#d_quickcheckout .has-error.radio-inline label {
    color: $color-second !important;
}

#d_quickcheckout .has-error .form-control {
    border-color: $color-second !important;
}


/* product list */

.product-thumb {
    margin-bottom: 30px;
    overflow: hidden;
    z-index: 100;
    position: relative;
    max-width: 360px;
    padding-bottom: 10px;
    border: 1px solid $color-grey-bg;
    &:hover {
      border: 1px solid $color-hover;
    }
}

.product-thumb__buttons {
text-align: center;
}

.com-wish {
    position: absolute;
    display: none;
    top: 7px;
    right: 7px;
    height: 60px;
    width: 100px;
    z-index: 300
}

.product-thumb:hover .com-wish {
    display: block
}

.com-wish button {
    background: #e6e6e6;
    color: #4a4a4a;
    height: 40px;
    width: 40px;
    margin-left: 7px;
    border: none
}

.com-wish button:hover {
    color: $color-hover;
}

.product-thumb .image {
    text-align: center;
    position: relative;
}

.product-thumb .image a {
    display: block;
}

// .product-thumb .image a:hover {
// 	opacity: 0.8;
// }
.product-thumb .image img {
    margin-left: auto;
    margin-right: auto;
}

.product-grid .product-thumb .image {
    float: none;
}

@media (min-width: 767px) {
    .product-list .product-thumb .image {
        float: left;
        padding: 0 15px;
    }
}

.product-thumb h4 {
    color: #4a4a4a;
    font-size: 14px;
    text-align: center;
    line-height: 18px;
    height: 65px;
    overflow: hidden;
    text-transform: uppercase;
    border-bottom:1px dashed #4f4f4f;
    padding-bottom: 8px;
}

.product-thumb h4 a {
    color: #4a4a4a;
}

.product-thumb .caption {
    padding: 0 20px;
    /*min-height: 180px;*/
}

.product-list .product-thumb .caption {
    margin-left: 230px;
}

@media (max-width: 1200px) {
    .product-grid .product-thumb .caption {
        min-height: 210px;
        padding: 0 10px;
    }
}

@media (max-width: 767px) {
    .product-list .product-thumb .caption {
        min-height: 0;
        margin-left: 0;
        padding: 0 10px;
    }
    .product-grid .product-thumb .caption {
        min-height: 0;
    }
}

.product-thumb .rating {
    padding-bottom: 10px;
}

.rating .fa-stack {
    font-size: 8px;
}

.rating .fa-star-o {
    color: #999;
    font-size: 15px;
}

.rating .fa-star {
    color: #FC0;
    font-size: 15px;
}

.rating .fa-star+.fa-star-o {
    color: #E69500;
}

h2.price {
    margin: 0;
}

.product-thumb .price {
    color: #4c4c4c;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}

.product-thumb .price-new {
    font-weight: 600;
}

.product-thumb .price-old {
    color: #ff4200;
    text-decoration: line-through;
    margin-left: 10px;
    font-size: 16px;
    font-weight: normal
}

.product-thumb .price-tax {
    color: #999;
    font-size: 12px;
    display: block;
}

.product-thumb .button-group {
    border-top: 1px solid #ddd;
    background-color: #eee;
    overflow: hidden;
}

.product-list .product-thumb .button-group {
    border-left: 1px solid #ddd;
}

@media (max-width: 768px) {
    .product-list .product-thumb .button-group {
        border-left: none;
    }
}

.product-thumb .button-group button {
    width: 60%;
    border: none;
    display: inline-block;
    float: left;
    background-color: #eee;
    color: #888;
    line-height: 38px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
}

.product-thumb .button-group button+button {
    width: 20%;
    border-left: 1px solid #ddd;
}

.product-thumb .button-group button:hover {
    color: #444;
    background-color: #ddd;
    text-decoration: none;
    cursor: pointer;
}

@media (max-width: 1200px) {
    .product-thumb .button-group button,
    .product-thumb .button-group button+button {
        width: 33.33%;
    }
}

@media (max-width: 767px) {
    .product-thumb .button-group button,
    .product-thumb .button-group button+button {
        width: 33.33%;
    }
}

.d-flex {
  display: flex;
}
.prod-quantity {
  display: inline-block;
  width: 200px;
  margin-right: 10px;
}
.prod-quantity-label {
  padding-top: 7px;
}
.prod_stock {
  display: inline-block;
  width: 200px;
  font-weight: bold;
  &.danger {
    color: #ce1126;
  }
}
.product-thumb {
  .prod_stock  {
    width: 100%;
    text-align: center;
  }
}
.contact-map {
  margin: 20px 0;
}
.thumbnails {
    overflow: auto;
    clear: both;
    list-style: none;
    padding: 0;
    margin: 0;
}

.thumbnails>li {
    margin-left: 20px;
}

.thumbnails {
    margin-left: -20px;
}

.thumbnails>img {
    width: 100%;
}

.image-additional a {
    margin-bottom: 20px;
    padding: 5px;
    display: block;
    border: 1px solid #ddd;
}

.image-additional {
    max-width: 78px;
}

.thumbnails .image-additional {
    float: left;
    margin-left: 20px;
}

@media (min-width: 1200px) {
    #content .col-lg-2:nth-child(6n+1),
    #content .col-lg-3:nth-child(4n+1),
    #content .col-lg-4:nth-child(3n+1),
    #content .col-lg-6:nth-child(2n+1) {
        clear: left;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    #content .col-md-2:nth-child(6n+1),
    #content .col-md-3:nth-child(4n+1),
    #content .col-md-4:nth-child(3n+1),
    #content .col-md-6:nth-child(2n+1) {
        clear: left;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    #content .col-sm-2:nth-child(6n+1),
    #content .col-sm-3:nth-child(4n+1),
    #content .col-sm-4:nth-child(3n+1),
    #content .col-sm-6:nth-child(2n+1) {
        clear: left;
    }
}


/* fixed colum left + content + right*/

@media (min-width: 768px) {
    #column-left .product-layout .col-md-3 {
        width: 100%;
    }
    #column-left+#content .product-layout .col-md-3 {
        width: 50%;
    }
    #column-left+#content+#column-right .product-layout .col-md-3 {
        width: 100%;
    }
    #content+#column-right .product-layout .col-md-3 {
        width: 100%;
    }
}


/* fixed product layouts used in left and right columns */

#column-left .product-layout,
#column-right .product-layout {
    width: 100%;
}


/* fixed mobile cart quantity input */

.input-group .form-control[name^=quantity] {
    min-width: 50px;
    border-radius: 0
}


/* Missing focus and border color to overwrite bootstrap */

.btn-info:hover,
.btn-info:active,
.btn-info.active,
.btn-info.disabled,
.btn-info[disabled] {
    background-image: none;
    background-color: #df5c39;
}


/* Animate alert box */

#alert-box {
    position: fixed;
    top: 45px;
    transition: all 0.5s;
    z-index: 99;
    right: -50%;
}

#alert-box.open {
    right: 15px;
}

@media screen and (max-width: 767px) {
    #alert-box {
        padding: 15px;
    }
    #alert-box.open {
        right: 0px;
    }
}


/* fixed alert-dismissable close position */

.alert-dismissable .close,
.alert-dismissible .close {
    right: -4px;
}

.brand-link__img {
    border: 1px solid #dfdfdf;
    padding: 15px;
    display: block;
}

.brand-link__img img {
    width: 100%
}

a.brand-link__txt {
    display: block;
    text-align: center;
    text-transform: uppercase;
    margin: 10px auto 30px
}

.back-to-top {
    background: rgba(255, 255, 255, .5);
    border: 1px solid $color-link;
    color: $color-link !important;
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 42px;
    height: 40px;
    display: none;
    transition: all .3s ease-out;
    padding: 10px 0 0;
    box-sizing: border-box;
    box-shadow: none;
    z-index: 999999;
}

.back-to-top:hover,
.back-to-top:focus {
    background: $color-link;
    color: $color-white !important;
    border-color: $color-link;
}

.price-status .action {
    position: absolute;
    left: 0;
    border-top: 67px solid #ff4200;
    width: 0;
    height: 0;
    top: 0;
    border-right: 67px solid transparent;
}

.price-status .caption-price-status {
    position: absolute;
    top: -55px;
    right: -40px;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    font-size: 11px;
    font-weight: bold;
    color: #FFF;
    display: block;
    white-space: nowrap;
}

.product_img .hit-status .action {
    right: 15px;
}

.product_img .new-status .action {
    left: 15px;
    bottom: 20px;
    top: auto;
}

.new-status .action {
    position: absolute;
    left: 0;
    /* border-top: 67px solid #007bff; */
    width: 120px;
    height: 30px;
    background: #007bff;
    top: 230px;
    text-align: center;
    padding: 5px;
    display: none;
    /* border-left: 67px solid transparent; */
}

.new-status .caption-new-status {
    /* position: absolute;
		top: -50px;
    left: -50px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg); */
    font-size: 11px;
    font-weight: bold;
    color: #FFF;
    display: block;
    white-space: nowrap;
}

.hit-status .action {
    position: absolute;
    right: 0;
    border-top: 67px solid #ff8a00;
    width: 0;
    height: 0;
    top: 0;
    border-left: 67px solid transparent;
}

.hit-status .caption-hit-status {
    position: absolute;
    top: -55px;
    left: -35px;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    font-size: 11px;
    font-weight: bold;
    color: #FFF;
    display: block;
    white-space: nowrap;
}

.product_img .price-status .action {
    left: 15px
}

.product-thumb .action {
    display: none;
}

.section-full {
    overflow: hidden;
}

.multiprice {
  margin: 50px 0;
  .multiprice__list {
    list-style: none;
    padding: 0;
    margin: 0;
    .multiprice__item {
      display: flex;
      margin-bottom: 15px;
      .multiprice__name {
        flex-grow:3;
        font-size: 16px;
      }
      .multiprice__quantity {
        flex-grow:3;
        padding-right: 20px;
        box-sizing: border-box;
        .form-control {
          height: 40px;
        }
      }
      .multiprice__link {
        flex-grow:9;
        .btn-addcart {
          margin: 0 auto;
          font-size: 14px;
          padding: 7px 25px;
        }
      }
    }
  }
}



#wrapper {
  padding-left: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled {
  padding-left: 250px;
}

#sidebar-wrapper {
  z-index: 1000;
  position: fixed;
  left: 250px;
  width: 0;
  height: 100%;
  margin-left: -250px;
  overflow-y: auto;
  background: #000;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
  width: 250px;
}

#page-content-wrapper {
  width: 100%;
  position: absolute;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-right: -250px;
}


/* Sidebar Styles */

.sidebar-nav {
  position: absolute;
  top: 0;
  width: 250px;
  margin: 0;
  padding: 25px 0;
  list-style: none;
}

.sidebar-nav li {
  line-height: 18px;
  padding: 8px 15px;
}

.sidebar-nav li a {
  display: block;
  text-decoration: none;
  color: #fff;
  &:hover {
    text-decoration: none;
    color: #fff;
  }
}

.sidebar-nav li:hover {
  background: rgba(255, 255, 255, 0.2);
}

.sidebar-nav li a:active, .sidebar-nav li a:focus {
  text-decoration: none;
}

.sidebar-nav>.sidebar-brand {
  height: 65px;
  font-size: 18px;
  line-height: 60px;
}

.sidebar-nav>.sidebar-brand a {
  color: #999999;
}

.sidebar-nav>.sidebar-brand a:hover {
  color: #fff;
  background: none;
}
#menu-toggle {
  position: fixed;
  top: 160px;
  left: 0px;
  background: #ce1126;
  color: #fff;
  padding: 10px 15px;
  border: 0;
  z-index: 2000;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  .fa {
    font-size: 18px;
  }
}
#menu-toggle.toggled {
  left: 250px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
@media(min-width:768px) {
  #wrapper {
    padding-left: 0;
  }
  #wrapper.toggled {
    padding-left: 250px;
  }
  #sidebar-wrapper {
    width: 0;
  }
  #wrapper.toggled #sidebar-wrapper {
    width: 250px;
  }
  #page-content-wrapper {
    position: relative;
  }
  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-right: 0;
  }
}
.hamburger {
  position: relative;
  display: block;
  width: 32px;
  height: 32px;
  margin: 0;
  background: transparent;
  border: none;
}
.hamburger:hover,
.hamburger:focus,
.hamburger:active {
  outline: none;
}
.hamburger.is-closed:before {
  content: '';
  display: block;
  width: 100px;
  font-size: 14px;
  color: #fff;
  line-height: 32px;
  text-align: center;
  opacity: 0;
  -webkit-transform: translate3d(0,0,0);
  -webkit-transition: all .35s ease-in-out;
}
.hamburger.is-closed:hover:before {
  opacity: 1;
  display: block;
  -webkit-transform: translate3d(-100px,0,0);
  -webkit-transition: all .35s ease-in-out;
}

.hamburger.is-closed .hamb-top,
.hamburger.is-closed .hamb-middle,
.hamburger.is-closed .hamb-bottom,
.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-middle,
.hamburger.is-open .hamb-bottom {
  position: absolute;
  left: 0;
  height: 4px;
  width: 100%;
}
.hamburger.is-closed .hamb-top,
.hamburger.is-closed .hamb-middle,
.hamburger.is-closed .hamb-bottom {
  background-color: #fff;
}
.hamburger.is-closed .hamb-top {
  top: 5px;
  -webkit-transition: all .35s ease-in-out;
}
.hamburger.is-closed .hamb-middle {
  top: 50%;
  margin-top: -2px;
}
.hamburger.is-closed .hamb-bottom {
  bottom: 5px;
  -webkit-transition: all .35s ease-in-out;
}

.hamburger.is-closed:hover .hamb-top {
  top: 0;
  -webkit-transition: all .35s ease-in-out;
}
.hamburger.is-closed:hover .hamb-bottom {
  bottom: 0;
  -webkit-transition: all .35s ease-in-out;
}
.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-middle,
.hamburger.is-open .hamb-bottom {
  background-color: #fff;
}
.hamburger.is-open .hamb-top,
.hamburger.is-open .hamb-bottom {
  top: 50%;
  margin-top: -2px;
}
.hamburger.is-open .hamb-top {
  -webkit-transform: rotate(45deg);
  -webkit-transition: -webkit-transform .2s cubic-bezier(.73,1,.28,.08);
}
.hamburger.is-open .hamb-middle { display: none; }
.hamburger.is-open .hamb-bottom {
  -webkit-transform: rotate(-45deg);
  -webkit-transition: -webkit-transform .2s cubic-bezier(.73,1,.28,.08);
}
.hamburger.is-open:before {
  content: '';
  display: block;
  width: 100px;
  font-size: 14px;
  color: #fff;
  line-height: 32px;
  text-align: center;
  opacity: 0;
  -webkit-transform: translate3d(0,0,0);
  -webkit-transition: all .35s ease-in-out;
}
.hamburger.is-open:hover:before {
  opacity: 1;
  display: block;
  -webkit-transform: translate3d(-100px,0,0);
  -webkit-transition: all .35s ease-in-out;
}


/* Animation */
@-webkit-keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    animation-duration: .4s;
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    animation-duration: .4s;
  }
}
@keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    animation-duration: .4s;
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    animation-duration: .4s;
  }
}
.rollIn {
  -webkit-animation-name: rollIn;
  animation-name: rollIn;
}


.opinion__addform {
  background: #f0f0f0;
  padding: 20px;
  max-width:700px;
  margin-bottom: 20px;
  margin-top: 20px;
  display: none;
}
.opinion__addform.active {
  display: block;
}
.opinion__submit,
.opinion__add {
  margin: 8px 0;
  padding: 8px 25px;
  font-size: 14px;
  width: auto;
  display: inline-block;
}
.overal {
  background: #dfdfdf;
  padding: 15px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.show {
  background: #f0f0f0;
  text-align: center;
  padding: 15px  0 !important;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.opinion__item {
  display: flex;
  margin: 15px 0;
  border-bottom: 1px dashed #dfdfdf;
  padding-bottom: 15px;
}
.opinion__data {
  width: 100px;
      min-width: 100px;
}
.opinion__desc p {
  margin-bottom: 10px;
}
.opinion__rating {
  font-size: 12px;
  color: #333;
}
.opinion .radio-inline {
  position: relative;
}
.opinion .radio-inline span {
  display: none;
  position: absolute;
  left: 0;
  top: -25px;
  width: auto;
  background: #fff;
  padding: 2px 8px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25);
  white-space: nowrap;
}
.opinion .radio-inline span:before {
  position: absolute;
  top: -15px;
  left: 2px;
  width: 0;
height: 0;
border-style: solid;
border-width: 10px 5px 0 5px;
border-color: #ffffff transparent transparent transparent;
}
.opinion .radio-inline:hover span {
  display: block;
}

.services {
 &__list {
   list-style: none;
   margin: 0;
   padding: 0;
 }
 &__item {
   list-style: none;
   margin: 0 0 20px;
   padding: 0;
   max-width: 100px;
 }
 &__link {
   img {
     width: 100%;
   }
 }
}
#d_quickcheckout #shipping_method_list {
  label {
    display: flex;
    justify-content: space-between;
    .price {
      white-space: nowrap;
    }
    .text {
      margin-right: 15px;
    }
  }
}

.cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  padding-top: 7px;
  background: rgba(255,0,0,0.8);
  color: #fff;
  z-index: 50000;
  a {
    color: #fff;
    margin-left: 15px;
    font-size: 14px;
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }
}




// Checkbox invert --------------------------------------//
#payment_address_shipping_address_input label{
	position: relative;
	cursor: pointer;
	color: #666;
	font-size: 30px;
}

#payment_address_shipping_address_input input[type="checkbox"], #payment_address_shipping_address_input input[type="radio"]{
	position: absolute;
	right: 9000px;
}

/*Check box*/
#payment_address_shipping_address_input input[type="checkbox"] + span:before{
  content: "\f14a";
	color: #000;
	font-family: "FontAwesome";
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing:antialiased;
	width: 1em;
  font-size: 18px;
	display: inline-block;
	margin-right: 5px;
}

#payment_address_shipping_address_input input[type="checkbox"]:checked + span:before{
  content: "\f0c8";
	color: #ccc;
}

#payment_address_shipping_address_input input[type="checkbox"]:disabled + span{
	color: #aaa;
}

#payment_address_shipping_address_input input[type="checkbox"]:disabled + span:before{
  content: "\f14a";
	color: #000;
	animation: effect 250ms ease-in;
}
#shipping_method_list {
  margin-top: 40px;
}
#shipping_method_list strong.title {
  font-weight: normal;
  font-size: 12px;
  margin-left: 20px;
  display: block;
  margin-bottom: -40px;
}
#inpostlockerpl_sel {
  position: relative;
  bottom: -20px;
}

@keyframes effect{
	0%{transform: scale(0);}
	25%{transform: scale(1.3);}
	75%{transform: scale(1.4);}
	100%{transform: scale(1);}
}
// End checkbox invert

/* Media */
@media (max-width: 767px) {
  .floating-menu {
    display: none;
  }
  #menu-toggle {
    top: 13px;
  }
  .top__right {
    display: block;
    text-align: center;
    .search__box {
      width: 250px;
      margin: 10px auto;
      max-width: 250px;
      .input-lg {
        transform: translateX(0);
      }
      .btn-lg {
        transform: translateX(0);
      }
    }
  }

  .top-user-block {
    text-align: center;
    margin-top: 5px;
  }
  .login .login__link {
    margin: 0 5px;
  }
  #logo {
    text-align: center;
    img {
      margin: 0 auto;
    }
  }
  .language-block {
    margin-top: 12px;
    }
  #cart {
    width: 100%;
    margin-top: 5px;
    text-align: center;
    &::after {
        content: '';
        clear: both;
        display: block
    }
    .btn-top-cart {
        display: inline-block;
        margin: 0 auto;
        max-width: 210px;
        position: relative;
        left: 50%;
        margin-left: -105px;
    }
  }

  #menu.navbar {
    margin-top: 10px;
  }
  #menu.navbar .flex-fill {
    display: block;
  }
  #menu .btn-navbar {
    background: #000;
    border-color: #fff;
  }
  #menu .btn-navbar.disabled, #menu .btn-navbar:active, #menu .btn-navbar:focus, #menu .btn-navbar:hover, #menu .btn-navbar[disabled] {
    background: #000;
    border-color: #fff;
  }
  #category {
    text-transform: uppercase;
  }
  #header .social {
    display: none;
  }
  .section-hero {
    padding: 50px 0;
    h1 {
      font-size: 36px;
    }
  }
  .brands_products .brands__list {
    display: block;
    .brands__item {
      width: 49%;
      display:inline-block;
      margin-bottom: 15px;
    }
  }
  .section-worktime {
    padding: 50px 0;
    margin-top: 50px;
    .worktime {
      margin-top: 30px;
    }
  }

  .pull-left,
  .pull-right {
    float: none !important;
  }
#column-left {
  margin-bottom: 20px;
}
#prod-recent__carusel {
  max-width: 90%;
}
.product-thumb {
  max-width: 100%;
}
.breadcrumb>li {
  white-space: normal;
}
  footer h5 {
      margin-bottom: 10px
  }
  footer .list-unstyled {
      margin-bottom: 25px
  }

  .language-block .btn-link {
      color: #fff
  }
  .language-block img {
      margin-right: 8px
  }

}
